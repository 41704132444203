import React from "react";
import Icon from "../../Icon";

import s from "../Footer.module.scss";

type Props = {
  text: string;
  onLangClick: () => void;
};

const LangPicker = ({ text, onLangClick }: Props) => {
  return (
    <button className={s.langPicker} onClick={onLangClick}>
      <Icon icon="world" width={12} color={s.iconcolor} />
      {text}
    </button>
  );
};

export default LangPicker;
