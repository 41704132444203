import React, { ReactNode } from "react";
import { motion } from "framer-motion";
import s from "./DesktopMenu.module.scss";

type Props = {
  index: number;
  children: ReactNode;
};

export const DesktopSubNavItem = ({ index, children }: Props) => (
  <motion.li
    initial={{
      opacity: 0,
      y: -20,
    }}
    animate={{ opacity: 1, y: 0 }}
    transition={{
      duration: 0.2,
      delay: index * 0.03,
    }}
    className={s.subNavItem}
  >
    {children}
  </motion.li>
);
