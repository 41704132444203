import React, { forwardRef, HTMLProps } from "react";
import cn from "classnames";
import s from "./DesktopMenu.module.scss";

type Props = {
  text: string;
  isActive: boolean;
  isSubNavOpen: boolean;
  onMouseEnter: () => void;
} & HTMLProps<HTMLAnchorElement>;

export const DesktopNavItem = forwardRef<HTMLAnchorElement, Props>(
  ({ text, isActive, isSubNavOpen, onMouseEnter, ...anchorProps }, ref) => {
    return (
      <li
        className={cn(s.navItem, {
          [s.active]: isActive && isSubNavOpen,
          [s.inactive]: !isActive && isSubNavOpen,
        })}
        onMouseEnter={onMouseEnter}
      >
        <a className={s.navItemLink} ref={ref} title={text} {...anchorProps}>
          {text}
        </a>
      </li>
    );
  }
);

DesktopNavItem.displayName = "NavItem";
