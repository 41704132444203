import React, { forwardRef } from "react";

import cn from "classnames";
import s from "./MobileMenu.module.scss";

import Flag from "../../../Flag";
import { FlagKey } from "../../../Flag/Flag";

type Props = {
  language: string;
  href: string;
  country: FlagKey;
};

export const MobileLanguageItem = forwardRef<HTMLAnchorElement, Props>(
  ({ language, href, country }, ref) => (
    <li className={cn(s.languageItem)}>
      <a ref={ref} href={href} className={cn(s.languageContent)}>
        <Flag country={country} width={23} />
        <span>{language}</span>
      </a>
    </li>
  )
);

MobileLanguageItem.displayName = "MobileLanguageItem";
