import React from "react";
import { motion } from "framer-motion";
import Icon from "../../../Icon";
import s from "./MobileMenu.module.scss";

type Props = {
  title: string;
  href: string;
  onBackClick: () => void;
};

export const MobileSubNavTitle = ({ title, href, onBackClick }: Props) => (
  <motion.div
    initial={{
      opacity: 0,
      x: 20,
    }}
    animate={{ opacity: 1, x: 0 }}
    transition={{ duration: 0.25 }}
    className={s.subNavTitle}
  >
    <button onClick={onBackClick} className={s.navItemButton}>
      <Icon icon="caret-left" color={s.iconcolor} />
    </button>
    <a href={href}>{title}</a>
  </motion.div>
);
