import s from "../Footer.module.scss";

type Props = {
  text: string;
};

const Copyright = ({ text }: Props) => {
  return <p className={s.copyright}>{text}</p>;
};

export default Copyright;
