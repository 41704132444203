import React, { forwardRef, HTMLProps, ReactNode } from "react";
import cn from "classnames";
import s from "./DesktopMenu.module.scss";

type Props = {
  icon?: ReactNode;
  title?: string;
  href?: string;
} & HTMLProps<HTMLAnchorElement>;

export const DesktopSubNavHeading = forwardRef<HTMLAnchorElement, Props>(
  ({ icon, title, href, ...anchorProps }, ref) => (
    <div className={s.heading}>
      {icon && icon}
      {href ? (
        <a
          className={s.title}
          href={href}
          ref={ref}
          title={title}
          {...anchorProps}
        >
          {title}
        </a>
      ) : (
        <p className={cn(s.title, s.noLink)}>{title}</p>
      )}
    </div>
  )
);

DesktopSubNavHeading.displayName = "SubNavHeading";
