import React, { ReactNode } from "react";
import cn from "classnames";
import s from "./MobileMenu.module.scss";

type Props = {
  isSubNavOpen: boolean;
  isSubSubNavOpen: boolean;
  children: ReactNode;
};

export const MobileNavList = ({
  isSubNavOpen,
  isSubSubNavOpen,
  children,
}: Props) => (
  <ul
    className={cn(s.navList, {
      [s.subNavOpen]: isSubNavOpen,
      [s.subSubNavOpen]: isSubSubNavOpen,
    })}
  >
    {children}
  </ul>
);
