import React, { ReactNode } from "react";
import cn from "classnames";
import s from "./Header.module.scss";
import c from "../../styles/container.module.scss";

type Props = {
  isMobileMenuOpen: boolean;
  isDesktopSubNavOpen: boolean;
  isTobiiGroup?: boolean;
  hidden?: boolean;
  children: ReactNode;
};

const Header = ({
  isMobileMenuOpen,
  isDesktopSubNavOpen,
  isTobiiGroup,
  hidden,
  children,
}: Props) => (
  <header className={cn(s.header, { [s.tobiiGroup]: isTobiiGroup })}>
    <div
      className={cn(c.fluid, s.header, s.fixed, {
        [s.isOpen]: isMobileMenuOpen,
        [s.isHidden]: hidden && !isMobileMenuOpen && !isDesktopSubNavOpen,
      })}
    >
      {children}
    </div>
  </header>
);

export default Header;
