import React, { forwardRef, HTMLProps } from "react";
import LinkWrapper from "../../../../components/Nestable/LinkWrapper/LinkWrapper";

import s from "./DesktopMenu.module.scss";

type Props = {
  text: string;
  href?: string;
} & HTMLProps<HTMLAnchorElement>;

export const DesktopSubNavLink = forwardRef<HTMLAnchorElement, Props>(
  ({ text, href, ...anchorProps }, ref) => {
    return (
      <LinkWrapper
        className={s.subNavLink}
        ref={ref}
        href={href}
        text={text}
        color={s.externaliconcolor}
        {...anchorProps}
      />
    );
  }
);

DesktopSubNavLink.displayName = "SubNavLink";
