import React, { ReactNode, useEffect } from "react";
import cn from "classnames";
import s from "./MobileMenu.module.scss";
import useScrollBlock from "../../../../hooks/useScrollBlock";
import useMediaQuery from "../../../../hooks/useMatchMedia";

import Icon from "../../../Icon";
import TobiiLogo from "../../../Logos/TobiiLogo";
import { useTranslation } from "next-i18next";

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  toggleOpen: () => void;
  isTobiiGroup?: boolean;
  children: ReactNode;
};

export const MobileMenu = ({
  isOpen,
  setIsOpen,
  toggleOpen,
  isTobiiGroup = false,
  children,
}: Props) => {
  const { t } = useTranslation();
  const [, setLocked] = useScrollBlock();
  const buttonColor = isOpen ? s.navbuttoncoloropen : s.navbuttoncolor;
  const isTouchDevice =
    useMediaQuery("(max-width: 1025px)") ||
    (typeof window !== "undefined" ? "ontouchstart" in window : false);

  useEffect(() => {
    setLocked(isOpen && isTouchDevice);

    if (isOpen && !isTouchDevice) {
      setIsOpen(false);
    }
  }, [isTouchDevice, isOpen, setIsOpen, setLocked]);

  return (
    <div className={s.mobileMenu}>
      <div
        className={cn(s.wrapper, {
          [s.isOpen]: isOpen,
          [s.tobiiGroup]: isTobiiGroup,
        })}
      >
        <a href="/" title="Tobii">
          <span className="visuallyHidden">{t("home")}</span>
          <TobiiLogo
            width={78}
            color={
              isOpen || isTobiiGroup ? s.tobiigroupiconcolor : s.buttoncolor
            }
          />
        </a>
        <button
          className={s.mobileNavButton}
          onClick={toggleOpen}
          title={t("menu")}
        >
          <Icon
            icon={isOpen ? "cancel" : "mobile-nav-button"}
            width={32}
            color={isTobiiGroup ? s.tobiigroupiconcolor : buttonColor}
          />
        </button>
      </div>
      {children}
    </div>
  );
};
