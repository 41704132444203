import Icon, { IconType } from "../../Icon/Icon";
import cn from "classnames";
import s from "../Share.module.scss";

type Props = {
  icon: IconType;
  href?: string;
  title?: string;
  onClick?: () => void;
};

const Item = ({ icon, href, title, onClick }: Props) => (
  <div className={cn(s.item)}>
    {onClick ? (
      <button onClick={onClick} title={title}>
        {icon && <Icon icon={icon} width={14} />}
      </button>
    ) : (
      <a
        href={href}
        rel="noopener noreferrer nofollow"
        title={title}
        target="_blank"
      >
        {icon && <Icon icon={icon} width={14} />}
      </a>
    )}
  </div>
);

export default Item;
