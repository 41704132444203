import React, { ReactNode } from "react";
import s from "./DesktopMenu.module.scss";

type Props = {
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  children: ReactNode;
};

export const DesktopNav = ({ onMouseEnter, onMouseLeave, children }: Props) => (
  <nav
    className={s.nav}
    onMouseEnter={onMouseEnter}
    onFocus={onMouseEnter}
    onMouseLeave={onMouseLeave}
    onBlur={onMouseLeave}
  >
    <ul className={s.navList}>{children}</ul>
  </nav>
);
