import React, { ReactNode, Children } from "react";
import cn from "classnames";
import s from "./Breadcrumbs.module.scss";
import BreadcrumbLink from "./components/BreadcrumbLink";

type Props = {
  inverted?: boolean;
  children: ReactNode[];
};
const Breadcrumbs = ({ inverted = false, children }: Props) => (
  <nav
    className={cn(s.breadcrumbs, {
      [s.inverted]: inverted,
    })}
  >
    <div className={s.inner}>
      {Children.map(children, (child) => {
        return <BreadcrumbLink inverted={inverted}>{child}</BreadcrumbLink>;
      })}
    </div>
  </nav>
);

export default Breadcrumbs;
