import cn from "classnames";
import LinkWrapper from "../../../components/Nestable/LinkWrapper/LinkWrapper";
import s from "../Footer.module.scss";

type Props = {
  text: string;
  href: string;
};

const LegalLink = ({ text, href }: Props) => (
  <LinkWrapper
    className={cn(s.link, s.legalLink)}
    href={href}
    text={text}
    color={"var(--foreground-i2)"}
  />
);

export default LegalLink;
