import LinkWrapper from "../../../components/Nestable/LinkWrapper/LinkWrapper";
import s from "../Footer.module.scss";

type Props = {
  text: string;
  href: string;
};

const Link = ({ text, href }: Props) => {
  return (
    <li>
      <LinkWrapper
        className={s.link}
        href={href}
        text={text}
        color={"var(--foreground-i2)"}
      />
    </li>
  );
};

export default Link;
