import React from "react";
import Icon from "../../Icon";
import s from "../Breadcrumbs.module.scss";

const BreadcrumbLink = ({
  inverted,
  children,
}: {
  inverted: boolean;
  children: React.ReactNode;
}) => {
  return (
    <div>
      <Icon
        icon="caret-right"
        width={12}
        color={inverted ? s.iconcolorinverted : s.iconcolor}
      />
      {children}
    </div>
  );
};
export default BreadcrumbLink;
