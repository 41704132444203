import React, { ReactNode } from "react";
import cn from "classnames";
import s from "./Footer.module.scss";
import c from "../../styles/container.module.scss";

import Column from "./components/Column";
import Link from "./components/Link";
import LangPicker from "./components/LangPicker";
import SoMeLinkList from "./components/SoMeLinkList";
import SoMeLink from "./components/SoMeLink";
import LegalLink from "./components/LegalLink";
import Copyright from "./components/Copyright";

const subComponents = {
  Column,
  Link,
  LangPicker,
  SoMeLinkList,
  SoMeLink,
  LegalLink,
  Copyright,
};
type SubComponents = typeof subComponents;

type Props = {
  children: ReactNode;
};

const Footer: ((props: Props) => JSX.Element) & SubComponents = ({
  children,
}: Props) => (
  <footer className={cn(c.fluid, s.footer)}>
    <div className={cn(c.xWide, s.content)}>{children}</div>
  </footer>
);

Footer.Column = Column;
Footer.Link = Link;
Footer.LangPicker = LangPicker;
Footer.SoMeLinkList = SoMeLinkList;
Footer.SoMeLink = SoMeLink;
Footer.LegalLink = LegalLink;
Footer.Copyright = Copyright;

export default Footer;
