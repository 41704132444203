import React, { ReactNode } from "react";
import { motion } from "framer-motion";
import cn from "classnames";

import s from "./DesktopMenu.module.scss";
import c from "../../../../styles/container.module.scss";

type Props = {
  gridLayout: number;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  children: ReactNode;
};

export const DesktopSubNav = ({
  gridLayout,
  onMouseEnter,
  onMouseLeave,
  children,
}: Props) => {
  const getGridLayout = () => {
    switch (gridLayout) {
      case 1:
        return s.gridLayout1;
      case 2:
        return s.gridLayout2;
      case 3:
        return s.gridLayout3;
      case 4:
        return s.gridLayout4;
      default:
        return s.gridLayout1;
    }
  };

  return (
    <motion.nav
      initial={{
        opacity: 0,
        y: -35,
      }}
      animate={{ opacity: 1, transform: "translateY(0)", y: -15 }}
      exit={{ opacity: 0, y: -35 }}
      transition={{ duration: 0.3 }}
      className={s.subNav}
      onMouseEnter={onMouseEnter}
      onFocus={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onBlur={onMouseLeave}
    >
      <ul className={cn(c.xWide, getGridLayout())}>{children}</ul>
    </motion.nav>
  );
};
