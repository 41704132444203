import React, {
  HTMLAttributes,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import cn from "classnames";
import s from "./AnchorMenu.module.scss";

import List from "./components/List";
import Item from "./components/Item";
import CTAItem from "./components/CTAItem";
import MobileMenu from "./components/MobileMenu";
import ExpandButton from "./components/ExpandButton";
import useEventListener from "../../lib/utils/useEventListener";

const subComponents = {
  List,
  Item,
  CTAItem,
  MobileMenu,
  ExpandButton,
};
type SubComponents = typeof subComponents;

type Props = {
  children: ReactNode;
  belowHeader: boolean;
} & HTMLAttributes<HTMLDivElement>;

const AnchorMenu: CWS<Props, SubComponents> = ({
  children,
  belowHeader,
  ...rest
}) => {
  const [originalOffsetTop, setOriginalOffsetTop] = useState<number>(-1);
  const [sticky, setSticky] = useState(false);

  const navRef = useRef<HTMLElement>(null);

  useEffect(() => {
    setOriginalOffsetTop(navRef?.current?.offsetTop || -1);
  }, []);

  const resolveSticky = () => {
    if (navRef.current) {
      const headerHeight =
        parseFloat(
          getComputedStyle(document.body).getPropertyValue("--header-height")
        ) * 16;

      const navOffset =
        navRef.current.offsetTop -
        2 -
        (belowHeader ? headerHeight : 0) +
        headerHeight;

      setSticky(
        window.pageYOffset > navOffset - headerHeight &&
          window.pageYOffset > originalOffsetTop - headerHeight
      );
    }
  };

  useEventListener("scroll", resolveSticky);

  useEventListener("resize", () => {
    if (navRef.current) {
      setOriginalOffsetTop(navRef.current.offsetTop);
    }
  });

  return (
    <>
      <nav
        ref={navRef}
        className={cn(s.anchorMenu, {
          [s.belowHeader]: belowHeader,
          [s.sticky]: sticky,
        })}
        {...rest}
      >
        <div className={cn(s.wrapper)}>{children}</div>
      </nav>
      {sticky && <div className={s.spacer} />}
    </>
  );
};

AnchorMenu.List = List;
AnchorMenu.Item = Item;
AnchorMenu.CTAItem = CTAItem;
AnchorMenu.MobileMenu = MobileMenu;
AnchorMenu.ExpandButton = ExpandButton;

export default AnchorMenu;
