import { ReactNode } from "react";

import TobiiLogo from "../../Logos/TobiiLogo";
import s from "../Footer.module.scss";

type Props = {
  title?: string;
  withIcon?: boolean;
  children: ReactNode;
};

const Column = ({ title, withIcon, children }: Props) => (
  <div>
    {title && <h2>{title}</h2>}
    {withIcon && <TobiiLogo width={100} color={s.iconcolor} />}
    {children}
  </div>
);

export default Column;
