import React, { forwardRef } from "react";
import { motion } from "framer-motion";
import cn from "classnames";
import Icon, { IconType } from "../../../Icon/Icon";
import s from "./MobileMenu.module.scss";
import { useIsExternalLink } from "../../../../hooks/useIsExternalLink";

type Props = {
  text: string;
  href: string;
  onClick?: () => void;
  icon?: IconType;
  isSubNavItem?: boolean;
  hasLinks: boolean;
  index: number;
};

export const MobileNavItem = forwardRef<HTMLAnchorElement, Props>(
  ({ text, href, onClick, icon, isSubNavItem, hasLinks, index }, ref) => {
    const { isExternalLink, shouldNofollow } = useIsExternalLink(href);

    // Function to determine the rel attribute
    const getRelAttribute = () => {
      if (isExternalLink && shouldNofollow) {
        return "noopener noreferrer nofollow";
      } else if (isExternalLink && !shouldNofollow) {
        return "noopener noreferrer";
      }
      return undefined; // No rel attribute needed for internal links
    };

    return (
      <motion.li
        initial={{
          opacity: 0,
          x: 20,
        }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.2, delay: (index + 1) * 0.025 }}
        className={cn(s.navItem, {
          [s.subNavItem]: isSubNavItem,
          [s.fullWidth]: !onClick,
        })}
      >
        <a
          ref={ref}
          href={href}
          className={cn({ [s.icon]: icon })}
          title={text}
          rel={getRelAttribute()}
        >
          {icon && <Icon icon={icon} width={18} color={s.iconcolor} />}
          {text}
        </a>

        {hasLinks && onClick && (
          <button onClick={onClick} className={s.navItemButton}>
            <Icon icon="caret-right" color={s.iconcolor} />
          </button>
        )}
      </motion.li>
    );
  }
);

MobileNavItem.displayName = "NavItem";
