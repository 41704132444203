import React from "react";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import { storyblokEditable } from "@storyblok/react";
import { HeaderSubNavGroupBlock } from "../../../lib/storyblok/types/blocks/HeaderSubNavGroupBlock";
import { StoryblokBlockComponent } from "../../../lib/storyblok/types/StoryblokComponent";
import { useLinks } from "../../../lib/storyblok/useLinks";
import {
  DesktopSubNavItem as DesktopSubNavItemUi,
  DesktopSubNavHeading,
  DesktopSubNavLink,
  DesktopSubNavButton,
} from "../../../ui/Header";

const DesktopSubNavItem: StoryblokBlockComponent<
  HeaderSubNavGroupBlock & { index: number }
> = (props) => {
  const { _uid, index, titleLink, title, icon, links } = props;

  const resolveLink = useLinks();
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: "grid",
      }}
      {...storyblokEditable(props)}
      key={_uid}
    >
      <DesktopSubNavItemUi index={index}>
        <DesktopSubNavHeading
          title={title}
          href={resolveLink(titleLink)}
          icon={
            <Image
              src={`/svg/icons/${icon}.svg`}
              width={24}
              height={24}
              alt={title}
            />
          }
        />
        <ul>
          {links.slice(0, 4).map((link) => (
            <DesktopSubNavLink
              key={link._uid}
              href={resolveLink(link.link)}
              text={link.text}
            />
          ))}
        </ul>
        {links.length > 4 && (
          <DesktopSubNavButton
            label={t("browse-all")}
            href={resolveLink(titleLink)}
            count={links.length}
          />
        )}
      </DesktopSubNavItemUi>
    </div>
  );
};

export default DesktopSubNavItem;
