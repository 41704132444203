import FooterUi from "../../ui/Footer";
import {
  FooterBlock,
  FooterLink,
} from "../../lib/storyblok/types/blocks/FooterBlock";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import { useLinks } from "../../lib/storyblok/useLinks";

const {
  Column,
  LangPicker,
  LegalLink,
  Copyright,
  Link,
  SoMeLinkList,
  SoMeLink,
} = FooterUi;

type FooterProps = FooterBlock & {
  onLangClick: () => void;
};

const Footer: StoryblokBlockComponent<FooterProps> = ({
  languageText,
  termsOfUseText,
  termsOfUseLink,
  privacyPolicyText,
  privacyPolicyLink,
  copyright,
  linkListATitle,
  linkListA,
  linkListBTitle,
  linkListB,
  socialMediaTitle,
  socialMediaList,
  onLangClick,
}) => {
  const resolveLink = useLinks();

  return (
    <FooterUi>
      <Column withIcon>
        <LangPicker text={languageText} onLangClick={onLangClick} />
        <ul>
          {termsOfUseText && (
            <li>
              <LegalLink
                text={termsOfUseText}
                href={resolveLink(termsOfUseLink)}
              />
            </li>
          )}
          {privacyPolicyText && (
            <li>
              <LegalLink
                text={privacyPolicyText}
                href={resolveLink(privacyPolicyLink)}
              />
            </li>
          )}
        </ul>
        {copyright.split("\n").map((line) => (
          <Copyright key={line} text={line} />
        ))}
      </Column>
      <LinkList linkList={linkListA} title={linkListATitle} />
      <LinkList linkList={linkListB} title={linkListBTitle} />
      <Column title={socialMediaTitle}>
        <SoMeLinkList>
          {socialMediaList.map((soMeItem) => (
            <SoMeLink
              key={soMeItem._uid}
              icon={soMeItem.icon}
              href={resolveLink(soMeItem.link)}
            />
          ))}
        </SoMeLinkList>
      </Column>
    </FooterUi>
  );
};

export default Footer;

export const LinkList = ({
  linkList,
  title,
}: {
  linkList: FooterLink[];
  title: string;
}) => {
  const resolveLink = useLinks();
  return (
    <Column title={title}>
      <ul>
        {linkList.map((link) => (
          <Link
            key={link._uid}
            text={link.text}
            href={resolveLink(link.link)}
          />
        ))}
      </ul>
    </Column>
  );
};
