import { useTranslation } from "next-i18next";
import Link from "next/link";
import { BreadCrumbsViewModel } from "../../lib/viewModel/createBreadcrumbsViewModel";
import Breadcrumbs from "../../ui/Breadcrumbs";

const BreadCrumbsUi = ({ crumbs, inverted }: BreadCrumbsViewModel) => {
  const { t } = useTranslation();
  return (
    <Breadcrumbs inverted={inverted}>
      {crumbs.map(({ href, name, isRoot }) => (
        <Link key={href} href={href} prefetch={false}>
          {isRoot ? t("navigation-root") : name}
        </Link>
      ))}
    </Breadcrumbs>
  );
};

export default BreadCrumbsUi;
