import React, { ReactNode } from "react";
import { motion } from "framer-motion";
import cn from "classnames";
import Icon, { IconType } from "../../../Icon/Icon";
import s from "./MobileMenu.module.scss";

type Props = {
  text: string;
  icon?: IconType;
  index: number;
  children?: ReactNode;
};

export const MobileLanguageList = ({ text, icon, index, children }: Props) => (
  <motion.li
    initial={{
      opacity: 0,
      x: 20,
    }}
    animate={{ opacity: 1, x: 0 }}
    transition={{ duration: 0.2, delay: (index + 1) * 0.025 }}
  >
    <div className={cn(s.navItem, s.languageHeader)}>
      {icon && <Icon icon={icon} width={18} color={s.navbuttoncoloropen} />}
      {text}
    </div>

    <ul className={cn(s.languageList)}>{children}</ul>
  </motion.li>
);
