/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { forwardRef, MouseEventHandler, ReactNode } from "react";
import { motion } from "framer-motion";
import s from "./DesktopMenu.module.scss";
import Button from "../../../Button";

type Props = {
  title: string;
  ctaText: string;
  ctaHref: string;
  backgroundImage: ReactNode;
  index: number;
  onClick?: MouseEventHandler;
};

export const DesktopSubNavCTA = forwardRef<HTMLAnchorElement, Props>(
  ({ title, ctaText, ctaHref, backgroundImage, index, onClick }, ref) => {
    return (
      <motion.li
        initial={{
          opacity: 0,
          y: -20,
        }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          duration: 0.2,
          delay: index * 0.03,
        }}
        className={s.subNavCTA}
      >
        {backgroundImage}
        <h3 className="display">{title}</h3>
        <Button
          ref={ref}
          variant="inverted"
          href={ctaHref}
          onClick={onClick}
          title={ctaText}
        >
          {ctaText}
        </Button>
      </motion.li>
    );
  }
);

DesktopSubNavCTA.displayName = "SubNavItem";
