import React, { useMemo, useState, useEffect } from "react";
import cn from "classnames";
import s from "../Hero.module.scss";

type Props = {
  videoUrl: string | undefined;
  delay?: boolean;
  noOverlay?: boolean;
  parallax?: boolean;
};

const VideoPlayer = ({ videoUrl, delay, noOverlay, parallax }: Props) => {
  const [loaded, setLoaded] = useState(false);
  const [video, setVideo] = useState<string>();

  const opacity = useMemo(() => {
    return loaded ? 0.7 : 1;
  }, [loaded]);

  // React does not rerender video element on videoUrl change unless we do this
  useEffect(() => {
    setVideo(videoUrl);
  }, [videoUrl]);

  return (
    <>
      <video
        className={cn(s.videoPlayer, { [s.parallax]: parallax })}
        width="100%"
        height="100%"
        preload="auto"
        muted
        autoPlay
        playsInline
        loop
        onPlay={() => {
          setTimeout(
            () => {
              setLoaded(true);
            },
            delay ? 3000 : 0
          );
        }}
        src={video}
      />
      <div className={cn({ [s.overlay]: !noOverlay })} style={{ opacity }} />
    </>
  );
};

export default VideoPlayer;
