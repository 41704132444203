import React, { ReactNode } from "react";
import cn from "classnames";

import s from "./DesktopMenu.module.scss";
import c from "../../../../styles/container.module.scss";

import Icon from "../../../Icon";
import TobiiLogo from "../../../Logos/TobiiLogo";
import { useTranslation } from "next-i18next";
import Button from "../../../Button";

type Props = {
  onLangClick?: React.MouseEventHandler<HTMLButtonElement>;
  onSearchClick?: React.MouseEventHandler<HTMLAnchorElement>;
  onLogoClick?: React.MouseEventHandler<HTMLAnchorElement>;
  searchHref?: string;
  clipPath: string;
  isTobiiGroup?: boolean;
  children: ReactNode;
};

export const DesktopMenu = ({
  onLangClick,
  onSearchClick,
  onLogoClick,
  clipPath,
  isTobiiGroup = false,
  searchHref,
  children,
}: Props) => {
  const logoWidth = "5.625rem";
  const toolsWidth = isTobiiGroup ? "16rem" : logoWidth;

  const { t } = useTranslation();

  return (
    <div
      style={{ clipPath, position: "relative" }}
      className={cn(s.desktopMenu, {
        [s.tobiiGroup]: isTobiiGroup,
      })}
    >
      <div className={cn(c.xWide, s.wrapper)}>
        <a
          href="/"
          style={{ width: logoWidth }}
          onClick={onLogoClick}
          title="Tobii"
        >
          <span className="visuallyHidden">{t("home")}</span>
          <TobiiLogo
            width={logoWidth}
            color={isTobiiGroup ? s.tobiigroupiconcolor : s.iconcolor}
          />
        </a>
        {children}
        <div className={s.langAndSearch} style={{ width: toolsWidth }}>
          {isTobiiGroup && (
            <Button
              variant="inverted"
              size="small"
              title={t("go-to-tobii-com")}
              href="https://www.tobii.com/"
            >
              {t("go-to-tobii-com")}
            </Button>
          )}
          <button onClick={onLangClick} title={t("change-language")}>
            <span className="visuallyHidden">{t("change-language")}</span>
            <Icon
              icon="world"
              width={18}
              color={isTobiiGroup ? s.tobiigroupiconcolor : s.iconColor}
            />
          </button>
          <a
            href={searchHref}
            onClick={onSearchClick}
            className={s.searchIcon}
            title={t("search")}
          >
            <span className="visuallyHidden">{t("search")}</span>
            <Icon
              icon="search"
              color={isTobiiGroup ? s.tobiigroupiconcolor : s.iconColor}
            />
          </a>
        </div>
      </div>
    </div>
  );
};
