import React, { forwardRef, HTMLProps } from "react";
import s from "./DesktopMenu.module.scss";

type Props = {
  label: string;
  href: string;
  count: number;
} & HTMLProps<HTMLAnchorElement>;

export const DesktopSubNavButton = forwardRef<HTMLAnchorElement, Props>(
  ({ href, label, count, ...anchorProps }, ref) => (
    <a
      className={s.browseAll}
      ref={ref}
      href={href}
      title={label}
      {...anchorProps}
    >
      {label}
      {count > 0 && ` (${count})`}
    </a>
  )
);

DesktopSubNavButton.displayName = "SubNavButton";
