import { AnimatePresence } from "framer-motion";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useEffect, useMemo, useRef, useState } from "react";
import { Language } from "../../../hooks/useLanguageSelect";
import { HeaderBlock } from "../../../lib/storyblok/types/blocks/HeaderBlock";
import { HeaderSubNavGroupBlock } from "../../../lib/storyblok/types/blocks/HeaderSubNavGroupBlock";
import { StoryblokBlockComponent } from "../../../lib/storyblok/types/StoryblokComponent";
import { useLinks } from "../../../lib/storyblok/useLinks";
import { useViewmodel } from "../../../lib/storyblok/ViewmodelContext";
import { storyblokEditable } from "@storyblok/react";

import HeaderUi, {
  MobileMenu,
  MobileNav,
  MobileNavList,
  MobileNavItem,
  MobileLanguageItem,
  MobileLanguageList,
  MobileSubNavTitle,
  DesktopMenu,
  DesktopNav,
  DesktopNavItem,
  DesktopSubNav,
  DesktopSubNavCTA,
} from "../../../ui/Header";
import ImageVaultImage from "../../ImageVaultImage";
import DesktopSubNavItem from "./DesktopSubNavItem";
import { useHeaderContext } from "./HeaderContext";

type HeaderProps = HeaderBlock & {
  onLangClick: () => void;
  languages: Language[];
};

const Header: StoryblokBlockComponent<HeaderProps> = ({
  navItems,
  tobiiGroup,
  searchPageLink,
  onLangClick,
  languages,
}) => {
  const resolveLink = useLinks();

  const { t } = useTranslation();
  const { locale } = useViewmodel();

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const [activeMobileNavItemIndex, setActiveMobileNavItemIndex] = useState(0);
  const [isMobileSubNavOpen, setIsMobileSubNavOpen] = useState(false);

  const [activeMobileSubNavItemIndex, setActiveMobileSubNavItemIndex] =
    useState(0);
  const [isMobileSubSubNavOpen, setIsMobileSubSubNavOpen] = useState(false);

  const [isDesktopSubNavOpen, setIsDesktopSubNavOpen] = useState(false);
  const [activeDesktopNavItemIndex, setActiveDesktopNavItemIndex] = useState(0);

  const { shouldHideHeader } = useHeaderContext();

  const navItemRefs = useRef<(HTMLAnchorElement | null)[]>([]);

  const { events, push } = useRouter();

  useEffect(() => {
    const closeMenu = () => {
      setIsMobileMenuOpen(false);
      setIsDesktopSubNavOpen(false);
    };
    events.on("routeChangeComplete", closeMenu);
    return () => {
      events.off("routeChangeComplete", closeMenu);
    };
  }, [events]);

  const clipPath = useMemo(() => {
    navItemRefs.current = navItemRefs.current.slice(0, navItems.length);
    return `polygon(0% 0%, 0% 100%, ${navItemRefs.current?.map((navItem, i) => {
      if (!navItem) return "";

      const x = navItem.offsetLeft + navItem.clientWidth / 2;
      const xLeft = x - 15;
      const xRight = x + 15;
      return `${xLeft}px 100%, ${x}px ${
        i === activeDesktopNavItemIndex &&
        isDesktopSubNavOpen &&
        navItems[activeDesktopNavItemIndex].subNavGroups.length > 0
          ? "75px"
          : "100%"
      }, ${xRight}px 100%`;
    })} , 100% 100%, 100% 0%)`;
  }, [activeDesktopNavItemIndex, isDesktopSubNavOpen, navItems]);

  const activeLanguage = languages.find(
    (language) => language.locale === locale
  );
  const remainingLanguages = languages.filter(
    (language) => !(language.locale === locale)
  );

  return (
    <HeaderUi
      isMobileMenuOpen={isMobileMenuOpen}
      isDesktopSubNavOpen={isDesktopSubNavOpen}
      isTobiiGroup={tobiiGroup}
      hidden={shouldHideHeader}
    >
      <MobileMenu
        isOpen={isMobileMenuOpen}
        setIsOpen={setIsMobileMenuOpen}
        toggleOpen={() => {
          setIsMobileMenuOpen(!isMobileMenuOpen);
          setIsMobileSubNavOpen(false);
          setIsMobileSubSubNavOpen(false);
        }}
        isTobiiGroup={tobiiGroup}
      >
        <MobileNav>
          <AnimatePresence>
            {isMobileMenuOpen && (
              <MobileNavList
                isSubNavOpen={isMobileSubNavOpen}
                isSubSubNavOpen={isMobileSubSubNavOpen}
              >
                {navItems?.map((item, i) => (
                  <MobileNavItem
                    key={item._uid}
                    text={item.title}
                    href={resolveLink(item.titleLink)}
                    hasLinks={item.subNavGroups && item.subNavGroups.length > 0}
                    index={i}
                    onClick={() => {
                      setActiveMobileNavItemIndex(i);
                      setIsMobileSubNavOpen(true);
                    }}
                  />
                ))}
                {tobiiGroup && (
                  <MobileNavItem
                    hasLinks={true}
                    icon="arrow-up-right"
                    text={t("go-to-tobii-com")}
                    href="https://www.tobii.com/"
                    index={navItems.length}
                  />
                )}
                <MobileNavItem
                  hasLinks={true}
                  icon="search"
                  text={t("search")}
                  href={resolveLink(searchPageLink)}
                  index={navItems.length}
                />
                {activeLanguage && (
                  <MobileLanguageList
                    text={activeLanguage?.label}
                    icon="world"
                    index={navItems.length}
                  >
                    {remainingLanguages.map(({ locale, label, href }) => (
                      <MobileLanguageItem
                        key={locale}
                        country={locale}
                        language={label}
                        href={href}
                      />
                    ))}
                  </MobileLanguageList>
                )}
              </MobileNavList>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {isMobileSubNavOpen && (
              <MobileNavList
                isSubNavOpen={isMobileSubNavOpen}
                isSubSubNavOpen={isMobileSubSubNavOpen}
              >
                <MobileSubNavTitle
                  title={navItems[activeMobileNavItemIndex].title}
                  href="#"
                  onBackClick={() => setIsMobileSubNavOpen(false)}
                />
                {navItems[activeMobileNavItemIndex].subNavGroups?.map(
                  (subNavGroup, i) => (
                    <MobileNavItem
                      key={subNavGroup._uid}
                      text={subNavGroup.title}
                      href={resolveLink(
                        subNavGroup.component === "headerSubNavGroup"
                          ? subNavGroup.titleLink
                          : subNavGroup.link
                      )}
                      isSubNavItem={true}
                      hasLinks={
                        subNavGroup.component === "headerSubNavGroup"
                          ? subNavGroup.links && subNavGroup.links.length > 0
                          : false
                      }
                      index={i}
                      onClick={() => {
                        setActiveMobileSubNavItemIndex(i);
                        setIsMobileSubSubNavOpen(true);
                      }}
                    />
                  )
                )}
              </MobileNavList>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {isMobileSubSubNavOpen && (
              <MobileNavList
                isSubNavOpen={isMobileSubNavOpen}
                isSubSubNavOpen={isMobileSubSubNavOpen}
              >
                <MobileSubNavTitle
                  title={
                    navItems[activeMobileNavItemIndex].subNavGroups[
                      activeMobileSubNavItemIndex
                    ].title
                  }
                  href="#"
                  onBackClick={() => setIsMobileSubSubNavOpen(false)}
                />

                {(
                  navItems[activeMobileNavItemIndex].subNavGroups[
                    activeMobileSubNavItemIndex
                  ] as HeaderSubNavGroupBlock
                ).links?.map((item, i) => (
                  <MobileNavItem
                    key={item._uid}
                    text={item.text}
                    href={resolveLink(item.link)}
                    hasLinks={false}
                    isSubNavItem={true}
                    index={i}
                  />
                ))}
              </MobileNavList>
            )}
          </AnimatePresence>
        </MobileNav>
      </MobileMenu>
      <DesktopMenu
        onLangClick={onLangClick}
        onLogoClick={(e) => {
          e.preventDefault();
          push("/");
        }}
        onSearchClick={(e) => {
          e.preventDefault();
          push(resolveLink(searchPageLink));
        }}
        searchHref={resolveLink(searchPageLink)}
        clipPath={clipPath ?? ""}
        isTobiiGroup={tobiiGroup}
      >
        <DesktopNav
          onMouseEnter={() => setIsDesktopSubNavOpen(true)}
          onMouseLeave={() => setIsDesktopSubNavOpen(false)}
        >
          {navItems?.map((item, i) => (
            <div
              style={{
                display: "grid",
              }}
              key={item._uid}
              {...storyblokEditable(item)}
            >
              <DesktopNavItem
                data-testid={item._uid}
                ref={(el) => (navItemRefs.current[i] = el)}
                text={item.title}
                isActive={activeDesktopNavItemIndex === i}
                isSubNavOpen={isDesktopSubNavOpen}
                onMouseEnter={() => setActiveDesktopNavItemIndex(i)}
                href={resolveLink(item.titleLink)}
              />
            </div>
          ))}
        </DesktopNav>
      </DesktopMenu>
      <AnimatePresence>
        {isDesktopSubNavOpen &&
          activeDesktopNavItemIndex !== null &&
          navItems[activeDesktopNavItemIndex].subNavGroups.length > 0 && (
            <DesktopSubNav
              gridLayout={parseInt(
                navItems[activeDesktopNavItemIndex].subNavGridLayout
              )}
              onMouseEnter={() => setIsDesktopSubNavOpen(true)}
              onMouseLeave={() => setIsDesktopSubNavOpen(false)}
            >
              {navItems[activeDesktopNavItemIndex].subNavGroups?.map(
                (subNavGroup, i) =>
                  subNavGroup.component === "headerCTA" ? (
                    <div
                      style={{
                        display: "grid",
                      }}
                      key={subNavGroup._uid}
                      {...storyblokEditable(subNavGroup)}
                    >
                      <DesktopSubNavCTA
                        title={subNavGroup.title}
                        ctaText={subNavGroup.linkText}
                        ctaHref={resolveLink(subNavGroup.link)}
                        backgroundImage={
                          <ImageVaultImage
                            sizes="25vw"
                            image={subNavGroup.backgroundImage}
                            layout="fill"
                          />
                        }
                        index={i}
                      />
                    </div>
                  ) : (
                    <DesktopSubNavItem
                      key={subNavGroup._uid}
                      {...subNavGroup}
                      index={i}
                    />
                  )
              )}
            </DesktopSubNav>
          )}
      </AnimatePresence>
    </HeaderUi>
  );
};
export default Header;
